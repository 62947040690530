define("discourse/plugins/discourse-reactions/discourse/controllers/user-activity-reactions", ["exports", "@ember/controller", "discourse-common/utils/decorators", "@ember/object", "discourse/plugins/discourse-reactions/discourse/models/discourse-reactions-custom-reaction"], function (_exports, _controller, _decorators, _object, _discourseReactionsCustomReaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    canLoadMore: true,
    loading: false,
    application: (0, _controller.inject)(),
    beforeLikeId: null,
    beforeReactionUserId: null,
    _getLastIdFrom(array) {
      return array.length ? array[array.length - 1].get("id") : null;
    },
    _updateBeforeIds(reactionUsers) {
      if (this.includeLikes) {
        const mainReaction = this.siteSettings.discourse_reactions_reaction_for_like;
        const [likes, reactions] = reactionUsers.reduce((memo, elem) => {
          if (elem.reaction.reaction_value === mainReaction) {
            memo[0].push(elem);
          } else {
            memo[1].push(elem);
          }
          return memo;
        }, [[], []]);
        this.beforeLikeId = this._getLastIdFrom(likes);
        this.beforeReactionUserId = this._getLastIdFrom(reactions);
      } else {
        this.beforeReactionUserId = this._getLastIdFrom(reactionUsers);
      }
    },
    loadMore() {
      if (!this.canLoadMore || this.loading || !this.reactionsUrl) {
        return;
      }
      this.set("loading", true);
      const reactionUsers = this.model;
      if (!this.beforeReactionUserId) {
        this._updateBeforeIds(reactionUsers);
      }
      const opts = {
        actingUsername: this.actingUsername,
        includeLikes: this.includeLikes,
        beforeLikeId: this.beforeLikeId,
        beforeReactionUserId: this.beforeReactionUserId
      };
      _discourseReactionsCustomReaction.default.findReactions(this.reactionsUrl, this.username, opts).then(newReactionUsers => {
        reactionUsers.addObjects(newReactionUsers);
        this._updateBeforeIds(newReactionUsers);
        if (newReactionUsers.length === 0) {
          this.set("canLoadMore", false);
        }
      }).finally(() => {
        this.set("loading", false);
      });
    },
    _showFooter() {
      this.set("application.showFooter", !this.canLoadMore);
    }
  }, [["method", "loadMore", [_object.action]], ["method", "_showFooter", [(0, _decorators.observes)("canLoadMore")]]]));
});