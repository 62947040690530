define("discourse/plugins/discourse-reactions/initializers/discourse-reactions", ["exports", "discourse/plugins/discourse-reactions/discourse/widgets/discourse-reactions-actions", "discourse/lib/plugin-api", "discourse-common/lib/icon-library", "discourse/lib/text"], function (_exports, _discourseReactionsActions, _pluginApi, _iconLibrary, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "discourse-reactions";
  (0, _iconLibrary.replaceIcon)("notification.reaction", "bell");
  function initializeDiscourseReactions(api) {
    api.removePostMenuButton("like");
    api.addKeyboardShortcut("l", null, {
      click: ".topic-post.selected .discourse-reactions-reaction-button"
    });
    api.decorateWidget("post-menu:before-extra-controls", dec => {
      const post = dec.getModel();
      if (!post || post.deleted_at) {
        return;
      }
      return dec.attach("discourse-reactions-actions", {
        post
      });
    });
    api.modifyClass("component:scrolling-post-stream", {
      pluginId: PLUGIN_ID,
      didInsertElement() {
        this._super(...arguments);
        const topicId = this?.posts?.firstObject?.topic_id;
        if (topicId) {
          this.messageBus.subscribe(`/topic/${topicId}/reactions`, data => {
            this.dirtyKeys.keyDirty(`discourse-reactions-counter-${data.post_id}`, {
              onRefresh: "reactionsChanged",
              refreshArg: data
            });
            this._refresh({
              id: data.post_id
            });
          });
        }
      }
    });
    api.modifyClass("controller:topic", {
      pluginId: PLUGIN_ID,
      unsubscribe() {
        this._super(...arguments);
        const topicId = this.model.id;
        topicId && this.messageBus.unsubscribe(`/topic/${topicId}/reactions`);
      }
    });
    api.decorateWidget("post-menu:extra-post-controls", dec => {
      if (dec.widget.site.mobileView) {
        return;
      }
      const mainReaction = dec.widget.siteSettings.discourse_reactions_reaction_for_like;
      const post = dec.getModel();
      if (!post || post.deleted_at) {
        return;
      }
      if (post.reactions && post.reactions.length === 1 && post.reactions[0].id === mainReaction) {
        return;
      }
      return dec.attach("discourse-reactions-actions", {
        post,
        position: "left"
      });
    });
    api.modifyClass("component:emoji-value-list", {
      pluginId: PLUGIN_ID,
      didReceiveAttrs() {
        this._super(...arguments);
        if (this.setting.setting !== "discourse_reactions_enabled_reactions") {
          return;
        }
        let defaultValue = this.values.includes(this.siteSettings.discourse_reactions_reaction_for_like);
        if (!defaultValue) {
          this.collection.unshiftObject({
            emojiUrl: (0, _text.emojiUrlFor)(this.siteSettings.discourse_reactions_reaction_for_like),
            isEditable: false,
            isEditing: false,
            value: this.siteSettings.discourse_reactions_reaction_for_like
          });
        } else {
          const mainEmoji = this.collection.findBy("value", this.siteSettings.discourse_reactions_reaction_for_like);
          if (mainEmoji) {
            mainEmoji.isEditable = false;
          }
        }
      }
    },
    // It's an admin component so it's not always present
    {
      ignoreMissing: true
    });
  }
  var _default = _exports.default = {
    name: "discourse-reactions",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (siteSettings.discourse_reactions_enabled) {
        (0, _pluginApi.withPluginApi)("0.10.1", initializeDiscourseReactions);
      }
    },
    teardown() {
      (0, _discourseReactionsActions.resetCurrentReaction)();
    }
  };
});