define("discourse/plugins/discourse-reactions/discourse/templates/user-activity-reactions", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#load-more selector=".user-stream-item" action=(action "loadMore")}}
    <div class="user-stream">
      {{#each model as |reaction|}}
        {{discourse-reactions-reaction-post reaction=reaction}}
      {{else}}
        <div class="alert alert-info">{{i18n "notifications.empty"}}</div>
      {{/each}}
    </div>
  
    {{conditional-loading-spinner condition=loading}}
  {{/load-more}}
  
  */
  {
    "id": "HSM1ofVw",
    "block": "[[[6,[39,0],null,[[\"selector\",\"action\"],[\".user-stream-item\",[28,[37,1],[[30,0],\"loadMore\"],null]]],[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"user-stream\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"model\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,4],null,[[\"reaction\"],[[30,1]]]]],[1,\"\\n\"]],[1]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,[28,[35,5],[\"notifications.empty\"],null]],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,6],null,[[\"condition\"],[[30,0,[\"loading\"]]]]]],[1,\"\\n\"]],[]]]]],[1,[28,[32,0],[\"[[\\\"The `model` property path was used in the `discourse/plugins/discourse-reactions/discourse/templates/user-activity-reactions.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.model}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `loading` property path was used in the `discourse/plugins/discourse-reactions/discourse/templates/user-activity-reactions.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.loading}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"reaction\"],false,[\"load-more\",\"action\",\"each\",\"-track-array\",\"discourse-reactions-reaction-post\",\"i18n\",\"conditional-loading-spinner\"]]",
    "moduleName": "discourse/plugins/discourse-reactions/discourse/templates/user-activity-reactions.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});