define("discourse/plugins/discourse-reactions/discourse/components/discourse-reactions-reaction-post", ["exports", "@ember/component", "discourse-common/utils/decorators", "discourse-common/lib/get-url", "discourse/lib/computed", "discourse/lib/text"], function (_exports, _component, _decorators, _getUrl, _computed, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    classNameBindings: [":user-stream-item", ":item", "moderatorAction"],
    postUrl(url) {
      return (0, _getUrl.default)(url);
    },
    emojiUrl(reactionValue) {
      if (!reactionValue) {
        return;
      }
      return (0, _text.emojiUrlFor)(reactionValue);
    },
    moderatorAction: (0, _computed.propertyEqual)("reaction.post.post_type", "site.post_types.moderator_action")
  }, [["method", "postUrl", [(0, _decorators.default)("reaction.post.url")]], ["method", "emojiUrl", [(0, _decorators.default)("reaction.reaction.reaction_value")]]]));
});