define("discourse/plugins/discourse-reactions/discourse/widgets/discourse-reactions-reaction-button", ["exports", "@ember/utils", "I18n", "discourse-common/lib/icon-library", "discourse/lib/text", "virtual-dom", "discourse/widgets/widget"], function (_exports, _utils, _I18n, _iconLibrary, _text, _virtualDom, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("discourse-reactions-reaction-button", {
    tagName: "div.discourse-reactions-reaction-button",
    buildKey: attrs => `discourse-reactions-reaction-button-${attrs.post.id}`,
    click() {
      this.callWidgetFunction("cancelCollapse");
      const currentUserReaction = this.attrs.post.current_user_reaction;
      if (!this.capabilities.touch || !this.site.mobileView) {
        this.callWidgetFunction("toggleFromButton", {
          reaction: currentUserReaction ? currentUserReaction.id : this.siteSettings.discourse_reactions_reaction_for_like
        });
      }
    },
    mouseOver(event) {
      this.callWidgetFunction("cancelCollapse");
      const likeAction = this.attrs.post.likeAction;
      const currentUserReaction = this.attrs.post.current_user_reaction;
      if (currentUserReaction && !currentUserReaction.can_undo && (!likeAction || (0, _utils.isBlank)(likeAction.can_undo))) {
        return;
      }
      if (!window.matchMedia("(hover: none)").matches) {
        this.callWidgetFunction("toggleReactions", event);
      }
    },
    mouseOut() {
      this.callWidgetFunction("cancelExpand");
      if (!window.matchMedia("(hover: none)").matches) {
        this.callWidgetFunction("scheduleCollapse", "collapseReactionsPicker");
      }
    },
    buildAttributes(attrs) {
      const likeAction = attrs.post.likeAction;
      if (!likeAction) {
        return {};
      }
      let title;
      let options;
      const currentUserReaction = this.attrs.post.current_user_reaction;
      if (likeAction.canToggle && (0, _utils.isBlank)(likeAction.can_undo)) {
        title = "discourse_reactions.main_reaction.add";
      }
      if (likeAction.canToggle && likeAction.can_undo) {
        title = "discourse_reactions.main_reaction.remove";
      }
      if (!likeAction.canToggle) {
        title = "discourse_reactions.main_reaction.cant_remove";
      }
      if (currentUserReaction && currentUserReaction.can_undo && (0, _utils.isBlank)(likeAction.can_undo)) {
        title = "discourse_reactions.picker.remove_reaction";
        options = {
          reaction: currentUserReaction.id
        };
      }
      if (currentUserReaction && !currentUserReaction.can_undo && (0, _utils.isBlank)(likeAction.can_undo)) {
        title = "discourse_reactions.picker.cant_remove_reaction";
      }
      return options ? {
        title: _I18n.default.t(title, options)
      } : {
        title: _I18n.default.t(title)
      };
    },
    html(attrs) {
      const mainReactionIcon = this.siteSettings.discourse_reactions_like_icon;
      const hasUsedMainReaction = attrs.post.current_user_used_main_reaction;
      const currentUserReaction = attrs.post.current_user_reaction;
      if (hasUsedMainReaction) {
        return (0, _virtualDom.h)("button.btn-toggle-reaction-like.btn-icon.no-text.reaction-button", {
          title: this.buildAttributes(attrs).title
        }, [(0, _iconLibrary.iconNode)(mainReactionIcon)]);
      }
      if (currentUserReaction) {
        return (0, _virtualDom.h)("button.btn-icon.no-text.reaction-button", {
          title: this.buildAttributes(attrs).title
        }, (0, _virtualDom.h)("img.btn-toggle-reaction-emoji.reaction-button", {
          src: (0, _text.emojiUrlFor)(currentUserReaction.id),
          alt: `:${currentUserReaction.id}:`
        }));
      }
      return (0, _virtualDom.h)("button.btn-toggle-reaction-like.btn-icon.no-text.reaction-button", {
        title: this.buildAttributes(attrs).title
      }, [(0, _iconLibrary.iconNode)(`far-${mainReactionIcon}`)]);
    }
  });
});