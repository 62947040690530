define("discourse/plugins/discourse-reactions/discourse/adapters/discourse-reactions-custom-reaction", ["exports", "discourse/plugins/discourse-reactions/discourse/adapters/discourse-reactions-adapter"], function (_exports, _discourseReactionsAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourseReactionsAdapter.default.extend({
    pathFor(store, type, findArgs) {
      const path = this.basePath(store, type, findArgs) + store.pluralize(this.apiNameFor(type));
      return this.appendQueryParams(path, findArgs);
    },
    apiNameFor() {
      return "custom-reaction";
    }
  });
});