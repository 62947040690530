define("discourse/plugins/discourse-reactions/discourse/routes/user-activity-reactions", ["exports", "discourse/routes/discourse", "discourse/plugins/discourse-reactions/discourse/models/discourse-reactions-custom-reaction"], function (_exports, _discourse, _discourseReactionsCustomReaction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _discourseReactionsCustomReaction.default.findReactions("reactions", this.modelFor("user").get("username"));
    },
    setupController(controller, model) {
      let loadedAll = model.length < 20;
      this.controllerFor("user-activity-reactions").setProperties({
        model,
        canLoadMore: !loadedAll,
        reactionsUrl: "reactions",
        username: this.modelFor("user").get("username")
      });
      this.controllerFor("application").set("showFooter", loadedAll);
    },
    renderTemplate() {
      this.render("user-activity-reactions");
    }
  });
});